<template>
  <section class="conOfAnnualMeeting h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <div class="conOfHeaderPages">
            <div class="sectionHeader mianTitleResposive"><span>Guide</span>liness</div>
          </div>
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }" attach 
              ></v-select>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12" v-if="listTopics">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.topic_id"
                :items="listTopics"
                item-text="title"
                item-value="id"
                label="Topic Name"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }" attach 
              ></v-select>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>
    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="6"
            cols="12"
            v-for="(item, index) in listItems"
            :key="index"
          >
            <div class="conofcard">
              <v-card class="mx-auto" max-width="370" tile elevation="1">
                <div class="conOfCardImg">
                  <v-img
                    height="210"
                    :src="item.image"
                    alt="item of the Image"
                  ></v-img>
                </div>
                <div class="conAllContent text-center py-3">
                  <v-card-title class="cardTitle">
                    <div class="titleSec">
                      {{ item.title }}
                    </div>
                  </v-card-title>
                  <div class="cardTitle">
                    <div class="topic_name">
                      {{ item.topic_name }}
                    </div>
                  </div>
                  <v-card-text>
                    <div class="conDownloadBtn">
                      <v-btn
                        class="primay-outline-btn"
                        outlined
                        :href="item.url"
                        target="_blank"
                      >
                        Preview
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    listTopics: null,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      topic_id: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Guideliness",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
    isLoadingSearch: false,
    updatedCurrent_page: null
  }),
  components: {
    EmptyState,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.topic_id = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.topic_id = parseInt(this.$route.query.topic_id) || null;
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "guideliness",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post("guidelines?page=" + this.pagination.current_page, this.search)
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    getTpoicList() {
      this.listTopics = null;
      apiServices.get("guideline-topics").then((res) => {
        if (res) {
          this.listTopics = res.data;
        }
      });
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getTpoicList();
  },
};
</script>
<style lang="scss" scoped>
@import "./_guideliness.scss";
</style>
